import Container from 'components/container'
import Layout from 'components/layout'
import Seo from 'components/molecules/seo'
import ArticlesTagList from 'components/organisms/articlesTagList'
import CommunitySearchBar from 'components/organisms/communitySearchBar'
import { graphql, PageProps } from 'gatsby'
import { getLocalNodes } from 'helpers/locale'
import React from 'react'

const perPage = 8

type communityTagTemplateProps = PageProps & {
  data: {
    contentfulArticleTag: {
      id: string
      title: string
      slug: string
      contentful_id: string
      node_locale: string
      description: { description: string }
    }
    articles: {
      nodes: {
        id: string
        title: string
        slug: string
        node_locale: string
        tags: [
          {
            id: string
            slug: string
            title: string
            internal: { type: string }
          }
        ]
        category: { id: string; slug: string; title: string }
      }
      totalCount: number
    }
  }
}

const communityTagTemplate = (
  props: communityTagTemplateProps
): JSX.Element => {
  const { contentfulArticleTag, articles: articlesData } = props.data
  const articles = getLocalNodes(articlesData.nodes)
  // eslint-disable-next-line @typescript-eslint/camelcase
  const { title, contentful_id } = contentfulArticleTag

  return (
    <Layout>
      <Seo
        title={title}
        url={props.location.href}
      />
      <CommunitySearchBar tag={title} />
      <Container>
        <ArticlesTagList
          articles={articles}
          // eslint-disable-next-line @typescript-eslint/camelcase
          tagSlug={contentful_id}
          more={articlesData.totalCount / 2 > articles.length}
          skip={perPage}
          uniform
          total={articlesData.totalCount / 2}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query contentfulArticleTag($slug: String, $locale: String) {
    contentfulArticleTag(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      contentful_id
      node_locale
    }
    articles: allContentfulArticle(
      limit: 16
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        id
        title
        node_locale
        slug
        category {
          id
          title
          slug
        }
        tags {
          id
          title
          slug
        }
        body {
          raw
        }
        metaDescription {
          metaDescription
        }
        heroImage {
          title
          fluid(maxWidth: 1600) {
            ...Image
          }
        }
        internal {
          type
        }
      }
      totalCount
    }
  }
`

export default communityTagTemplate
